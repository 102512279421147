<h2 class="alert-header" mat-dialog-title>
  <ng-content select="#header"></ng-content>
  <mat-icon class="close-icon" (click)="onClose()">close</mat-icon>
</h2>
<mat-dialog-content>
  <ng-content select="#content"></ng-content>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="back-btn">{{ "Common.Exit" | translate }}</button>
  <button (click)="onClose()" class="next-btn">{{"Common.Continue" | translate}}</button>
</mat-dialog-actions>
