<div class="title-bar">
  <img [src]="image" *ngIf="image.trim().length != 0" />
  <h1>{{ "Header.Title.Label" | translate }}</h1>
  <div style="margin-left: auto; padding: 15px 0 0 0">
    <mat-icon style="padding: 0 10px 0 0">language</mat-icon>
    <mat-form-field>
      <mat-label>{{ "LanguagePicker.Select" | translate }}</mat-label>
      <mat-select [(value)]="selectedLanguage" (selectionChange)="updateLanguage($event)">
        <mat-option value="en-CA">English (CA)</mat-option>
        <mat-option value="en-US">English (US)</mat-option>
        <mat-option value="es-MX">Español (MX)</mat-option>
        <mat-option value="fr-CA">Français (CA)</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

<div class="background-img">
  <svg width="418" height="968" viewBox="0 0 418 968" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M280.491 460.202C77.1865 444.319 23.7132 290.782 22.3896 215.999V489.983C43.0377 671.051 203.06 718.965 280.491 720.289V460.202Z"
      fill="#182129"
    />
    <path
      d="M280.491 -57.9855C77.1865 -73.8686 23.7132 -227.406 22.3896 -302.189V-28.2046C43.0377 152.863 203.06 200.777 280.491 202.101V-57.9855Z"
      fill="#182129"
    />
    <path d="M538.592 460.202H280.491V720.289C491.736 704.406 540.577 540.28 538.592 460.202Z" fill="#97D4ED" />
    <path
      d="M279.527 -56.9644L279.527 201.137L539.613 201.137C523.73 -10.1091 359.604 -58.9498 279.527 -56.9644Z"
      fill="#25B0A1"
    />
    <path
      d="M144.684 983.654C259.702 976.683 286.641 885.627 285.189 840.97H144.684V983.654Z"
      fill="#25B0A1"
      stroke="#25B0A1"
      stroke-width="2.17838"
    />
    <path
      d="M2 983.652C11.5849 869.505 101.116 840.242 144.684 840.968V983.652H2Z"
      fill="#182129"
      stroke="#182129"
      stroke-width="2.17838"
    />
    <circle cx="338.433" cy="749.401" r="73.6302" fill="#182129" />
    <path
      d="M381.819 973.84C291.711 966.8 268.011 898.75 267.424 865.605V987.039C276.576 1067.29 347.5 1088.53 381.819 1089.11V973.84Z"
      fill="#1A2A32"
    />
  </svg>
</div>
<mat-progress-bar class="loading-progress" mode="indeterminate" *ngIf="showLoad"></mat-progress-bar>
<router-outlet></router-outlet>
