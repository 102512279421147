import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-base-alert-dialog",
  templateUrl: "./base-alert-dialog.component.html",
  styleUrls: ["./base-alert-dialog.component.scss"],
})
/**
 * Represents the base alert dialog component.
 */
export class BaseAlertDialogComponent implements OnInit {
  @Input() title: string = "";
  @Input() onClose: () => void = () => {return};
  constructor() {

  }

  ngOnInit(): void {}
}
