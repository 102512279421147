<div class="transactionContainer">
  <mat-icon>subdirectory_arrow_right</mat-icon>
  <mat-expansion-panel [expanded]="this.transaction.expand" #trxPanel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="accordionHeaderTitle">
          {{
            this.transaction.merchant
              ? this.transaction.merchant
              : ("ImpactedPayments.PaymentMethod.Transaction" | translate)
          }}
        </span>
      </mat-panel-title>
      <mat-panel-description>
        <span *ngIf="!transaction.isPrePop">
          <span *ngIf="this.transaction.transactionId" class="accordionHeaderTrxId">
            ({{ "ImpactedPayments.Transaction.ID" | translate }}: {{ this.transaction.transactionId }})
          </span>
        </span>
        <span *ngIf="this.transaction.transactionAmount" class="accordionHeaderVL"></span>
        <span *ngIf="this.transaction.transactionAmount" class="accordionHeaderTrxAmount">
          {{ this.transaction.transactionAmount | currency }}</span
        >
      </mat-panel-description>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="stopProp($event)" *ngIf="!transaction.isPrePop">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="deleteTransaction()">
          <span>{{ "Common.Delete" | translate }}</span>
        </button>
      </mat-menu>
      <button mat-icon-button>
        <mat-icon>{{ trxPanel.expanded ? "remove" : "add" }}</mat-icon>
      </button>
    </mat-expansion-panel-header>
    <form [formGroup]="trxGroup">
      <div class="trxInputs">
        <div class="inputBlock">
          <div class="inputRow">
            <mat-form-field appearance="outline">
              <mat-label>{{ "ImpactedPayments.Transaction.Merchant" | translate }}</mat-label>
              <input matInput type="text" formControlName="merchant" />
              <mat-error *ngIf="trxGroup.get('merchant')?.hasError('required')">{{
                "Error.Required" | translate
              }}</mat-error>
              <mat-error
                *ngIf="
                  trxGroup.get('merchant')?.hasError('minlength') || trxGroup.get('merchant')?.hasError('maxlength')
                "
              >
                {{ "Error.MustBe1-60Chars" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ "Common.Amount" | translate }}</mat-label>
              <input matInput type="number" formControlName="transactionAmount" min="0.01" />
              <mat-error *ngIf="trxGroup.get('transactionAmount')?.hasError('required')">
                {{ "Error.Required" | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  trxGroup.get('transactionAmount')?.hasError('min') ||
                  trxGroup.get('transactionAmount')?.hasError('max')
                "
              >
                {{ "Error.ValidDollarAmount" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="inputRow" *ngIf="transaction.productType != productTypeEnum.MONEYCODE && !transaction.isPrePop">
            <mat-form-field appearance="outline">
              <mat-label *ngIf="transaction.lineOfBusiness === 'NAF'">{{
                "ImpactedPayments.Transaction.TransactionId_TransId" | translate
              }}</mat-label>
              <mat-label
                *ngIf="transaction.lineOfBusiness === 'OTR' && transaction.productType != productTypeEnum.SMARTFUNDS"
                >{{ "ImpactedPayments.Transaction.TransactionId_Acquirer" | translate }}</mat-label
              >
              <mat-label *ngIf="transaction.productType == productTypeEnum.SMARTFUNDS">{{
                "ImpactedPayments.Transaction.TransactionId_InvoiceNumber" | translate
              }}</mat-label>
              <input
                *ngIf="transaction.lineOfBusiness === 'NAF'"
                matInput
                type="text"
                formControlName="transactionId"
                mask="{{ trxIdMask }}"
              />
              <input
                *ngIf="transaction.lineOfBusiness === 'OTR'"
                matInput
                type="text"
                formControlName="transactionId"
              />
              <mat-error *ngIf="trxGroup.get('transactionId')?.hasError('required')">
                {{ "Error.Required" | translate }}
              </mat-error>
              <mat-error *ngIf="trxGroup.get('transactionId')?.hasError('mask')">
                {{ "Error.MustBe11-15NumericChars" | translate }}
              </mat-error>
              <mat-error
                *ngIf="
                  !trxGroup.get('transactionId')?.hasError('mask') &&
                  (trxGroup.get('transactionId')?.hasError('minlength') ||
                    trxGroup.get('transactionId')?.hasError('maxlength'))
                "
              >
                {{ "Error.MustBe1-40Chars" | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="inputRow">
            <mat-form-field appearance="outline">
              <mat-label>{{ "ImpactedPayments.Transaction.TransactionDate" | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="trxDate"
                formControlName="transactionDate"
                [max]="maxDate"
                [errorStateMatcher]="DateMatcher"
              />
              <mat-datepicker-toggle matSuffix [for]="trxDate"></mat-datepicker-toggle>
              <mat-datepicker #trxDate></mat-datepicker>
              <mat-error
                *ngIf="
                  trxGroup.get('trxDate')?.hasError('required') || trxGroup.get('trxDate')?.hasError('invalidDate')
                "
                >{{ "Error.ValidDateRequired" | translate }}</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf = "!transaction.isPrePop">
              <mat-label>{{ "ImpactedPayments.Transaction.MerchantContactDate" | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="dateMerchContacted"
                formControlName="merchantContactDate"
                [max]="maxDate"
                [errorStateMatcher]="DateMatcher"
              />
              <mat-datepicker-toggle matSuffix [for]="dateMerchContacted"></mat-datepicker-toggle>
              <mat-datepicker #dateMerchContacted></mat-datepicker>
              <mat-error *ngIf="trxGroup.get('dateMerchContacted')?.hasError('invalidDate')">{{
                "Error.InvalidDate" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</div>
